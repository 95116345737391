<template>
  <div class="wrap">
    <div class="wrap-header">
      <el-breadcrumb>
        <el-breadcrumb-item class="font-breadcrumb"><a>Load AFSA Data </a></el-breadcrumb-item>
        <el-breadcrumb-item> </el-breadcrumb-item>
      </el-breadcrumb>
      <div class="wrap-header-btn">
        <el-button type="primary" plain size="small" class="public-fonts" @click="query()">Query</el-button>
        <el-button type="primary" plain size="small" class="public-button-color public-fonts" @click="byAwbQuery('by')">Load data from AFSA</el-button>
      </div>
    </div>
    <div class="wrap-content">
      <el-table
        :data="loadData"
        class="content-table">
        <el-table-column
          prop="awb_no"
          label="AWB#"
          label-class-name="title">
        </el-table-column>
        <el-table-column
          prop="mawb_no"
          label="MAWB#"
          label-class-name="title">
        </el-table-column>
        <el-table-column
          prop="origin"
          label="Origin"
          label-class-name="title">
        </el-table-column>
        <el-table-column
          prop="dest"
          label="Dest."
          label-class-name="title">
        </el-table-column>
        <el-table-column
          label="Flight Date"
          label-class-name="title">
          <template slot-scope="scope">
            <span>{{ scope.row.loc_flt_datetime }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="load_date"
          label="Data Load Time(GMT)"
          label-class-name="title">
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <el-pagination
        @size-change="sizeChange"
        @current-change="currentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="pageSize"
        layout="total, prev, slot, next, sizes, jumper, ->"
        :total="total">
        <span class="on-pager">{{ currentPage }}</span>
      </el-pagination>
    </div>
    <el-drawer
      :title="flagQuery !== null ? 'Load Data Criteria':'Query Criteria'"
      :visible.sync="querydrawer"
      :show-close="false"
      :direction="direction"
      :size="332">
      <ByAwbQuery v-if="flagQuery !== null" @awbResultdrawer="awbResultdrawer"></ByAwbQuery>
      <Query v-else @query-drawer="changeQuerydrawer"></Query>
    </el-drawer>
  </div>
</template>

<script>
import Query from '../afsa-data/query.vue'
import ByAwbQuery from '../afsa-data/by-awb-query.vue'
import { getFasaData } from '../../../api/afsa-data/afsa-api'
import { getOfficeCode } from '@/utils/auth'

export default {
  name: 'afsa-data-list',
  data () {
    return {
      loadData: [],
      resDate: [],
      currentPage: 1, // 当前页
      querydrawer: false, // 查询抽屉
      direction: 'rtl', // 查询抽屉弹出的方向
      flagQuery: null,
      title: null,
      total: 0, // 总条数
      pageSize: 10 // 默认一页10条
    }
  },
  components: {
    Query,
    ByAwbQuery
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      const obj = {
        data: {
          officeCode: getOfficeCode(),
          pageSize: this.pageSize,
          currentPage: this.currentPage
        },
        loading: true
      }
      getFasaData(obj).then(res => {
        if (res.data.CODE === 200) {
          const loadData = res.data.DATA
          this.loadData = loadData.datas
          this.total = loadData.totalNum
          this.currentPage = loadData.currentPage
        } else {
          if (res.data.MESSAGE) {
            this.$message({
              dangerouslyUseHTMLString: true,
              message: `${res.data.MESSAGE}`,
              type: 'error',
              showClose: true,
              duration: 8000,
              offset: 120
            })
          }
        }
      }).catch(err => {
        this.$message.error(err)
      })
    },
    sizeChange (val) {
      this.pageSize = val
      const obj = {
        data: {
          officeCode: getOfficeCode(),
          pageSize: val,
          currentPage: 1
        },
        loading: true
      }
      getFasaData(obj).then(res => {
        if (res.data.CODE === 200) {
          const loadData = res.data.DATA
          this.loadData = loadData.datas
          this.total = loadData.totalNum
          this.currentPage = loadData.currentPage
        }
      }).catch(err => {
        console.log(err)
      })
    },
    currentChange (val) {
      const obj = {
        data: {
          officeCode: getOfficeCode(),
          pageSize: this.pageSize,
          currentPage: val
        },
        loading: true
      }
      getFasaData(obj).then(res => {
        if (res.data.CODE === 200) {
          const loadData = res.data.DATA
          this.loadData = loadData.datas
          this.total = loadData.totalNum
          this.currentPage = loadData.currentPage
        }
      }).catch(err => {
        console.log(err)
      })
    },
    query () {
      this.flagQuery = null
      this.querydrawer = true
    },
    changeQuerydrawer (data) {
      this.querydrawer = data.close
    },
    byAwbQuery (flag) {
      this.flagQuery = flag
      this.querydrawer = true
    },
    awbResultdrawer (data) {
      this.querydrawer = data.close
    }
  }
}
</script>

<style lang="scss" scoped>
$bg1:#1890ff;
.wrap {
  .wrap-header {
    display: flex;
    padding: 15px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e5e5e5;
  }
  .wrap-content{
    padding: 5px 24px 24px;
    min-height: 72vh;
    .content-table{
      width: 100%;
      border-radius: 8px;
    }
    ::v-deep {
       .title{
        color: #000;
        font-size: 16px;
      }
    }
  }
}
</style>
